<template>
    <Page>
        <FormTruck
            v-if="truck"
            :id="truck"
            :truckName="truckName"
            :toggle="toggle"
            center
            contents
        />
        <div v-show="!truck">
            <FormTransportSearch v-model="search" @search="reload"/>
            <div class="buttons">
                <button class="btn btn-blue btn-small" @click="openForm">
                    Добавить
                </button>
            </div>
            <DataTable
                :key="load"
                :cols="{
                    id: 'ID',
                    model: 'Модель',
                    number: 'Гос.номер',
                    status: 'Статус',
                    vin: 'VIN',
                    year: 'Год выпуска',
                    color: 'Цвет',
                }"
                :fetch="fetchData"
                paginate
                :click="toggle"
                class="mt-20"
            />
            <Popup name="popup-add-truck">
                <FormTruck :toggle="toggle" inflect/>
            </Popup>
        </div>
    </Page>
</template>

<script>

import FormTruck from './form/FormTruck.vue'
import FormTransportSearch from './form/FormTransportSearch.vue'

export default {
    name: "Trucks",
    props: {
        id: Number,
    },
    components: {
        FormTruck,
        FormTransportSearch
    },
    data() {
        return {
            load: 0,
            truck: null,
            search: {data: ''},
            truckName: ''
        }
    },
	watch: {
		$route (to){
			if (!to.query.id) {
				this.truck = null
				this.$router.replace({name: 'Trucks', query: null});
			}
		}
	},
    created() {
        _.isNumber(this.id) && this.$set(this, 'truck', this.id)
    },
    methods: {
        reload() {
            this.load += 1
        },
        fetchData(h, a) {
            this.$repo('transport').trucks({search: this.search.data, ...a}).then(({list}) => h(list))
        },
        openForm() {
            this.$popup('popup-add-truck', {
                clickToClose: false,
                onOpen: this.toggle
            })
        },
        toggle(item) {
            if (!item || item === true) {
                this.truck = null
                item === true && this.reload()
                this.$redirect({name: 'Trucks'}, true)
                return
            }
            this.truckName = `${item.model} - ${item.number}`
            this.truck = item.id
			this.$router.push({name: 'Trucks', query: {id: item.id}})
        }
    }
}

</script>
