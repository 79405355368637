<template>
    <div class="form-find-select" v-click-outside="close">
        <input class="form-input" v-model="searchString" :disabled="isDisabled" v-on:keyup.enter="onEnter" @click="drop" @keyup="drop(); type()"/>
        <Dropdown :name="dropdownName">
            <template v-for="(option, k) in (filteredOptions || options)">
                <div class="form-find-select-item" :key="k" @click="select(k)">
                    {{ isArrayOfObjects ? option[labelName] : option }}
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import {FORM_ITEM_COMPONENT_TAG} from "@ui/form/Form";

export default {
    name: "FormFindSelect",
    props: {
        index: Number,
        value: [Number, String],
        rules: Array,
        options: [Array, Object],
        label: {
            type: [String, Function],
            default: 'id',
        },
        labelName: {
            type: [String, Function],
            default: 'name',
        },
        objectsArray: Boolean,
        watch: Function,
        format: Function,
        required: {
            type: [Boolean, Function],
            default: null
        }
    },
    computed: {
        isArrayOfObjects() {
            return Boolean(_.isArrayOfObjects(this.options) || this.objectsArray)
        },
        isDisabled() {
            const attrs = this.getAttributes()
            return attrs.disabled !== undefined
                ? attrs.disabled === '' || (attrs.disabled !== '0' && attrs.disabled !== false)
                : false
        },
    },
    data() {
        return {
            val: this.value,
            searchString: '',
            validator: null,
            dropdownName: 'find-select-' + this.$uuid.v1(),
            filteredOptions: null
        }
    },
    created() {
        this.setSearchString()
        let rules = this.rules || []
        this.required && rules.push(_.isFunction(this.required) ? this.required : 'required')
        this.required === false && (rules = rules.filter(r => r !== 'required'))
        rules.length && (this.validator = new this.$service.validation({rules, name: this.$attrs.name}))
    },
    mounted() {
        this.$nextTick(() => {
            const wrap = this.$getParent({tag: FORM_ITEM_COMPONENT_TAG})
            wrap && !wrap.controls && wrap.setControls()
        })
        this.isDisabled || (this.validator && this.validator.validate(this.val))
    },
    methods: {
        input() {

            let val = this.val

            if (this.format) {
                val = this.format(val)
            }
            if (this.validator && !this.isDisabled) {
                this.$emit('error', this.validator.validate(val))
            }
            if (this.watch) {
                const value = this.watch(val, this)
                value !== undefined && (val = value)
            }

            this.setSearchString()

            this.$emit('input', val)
        },
        getAttributes() {
            return this.$attrs || {}
        },
        drop() {
            this.filteredOptions = null
            this.$popup(this.dropdownName)?.isOpen || this.$dropdown(this.dropdownName, {
                el: this.$el,
                fullscreen: false,
                clickToClose: false,
                showOverlay: false,
                style: {
                    maxWidth: '300px',
                    maxHeight: '400px',
                },
            })
        },
        select(index) {
            const source = this.filteredOptions || this.options
            this.val = this.isArrayOfObjects ? source[index][this.label] : index
            this.setSearchString()
            this.close()
            this.input()
        },
        onEnter() {
            const source = this.filteredOptions || this.options
			if (source.length!=0){
            this.val = this.isArrayOfObjects ? source[0][this.label] : 0
            this.setSearchString()
            this.close()
            this.input()
			}
			else {
            this.close()
			}
        },
        type() {

            const value = this.searchString

            if (_.isEmpty(value)) {
                this.filteredOptions = null
                this.val = ""
                this.input()
                return
            }

            const pattern = (v) => v.match(new RegExp(`(${value})`, 'gi'))

            let label = this.labelName
            let options = []

            if (_.isPlainObject(this.options)) {
                label = 'value'
                Object.keys(this.options).forEach(key => {
                    pattern(this.options[key]) !== null && options.push({key, [label]: this.options[key]})
                })
            } else {
                options = this.isArrayOfObjects
                    ? this.options.filter(v => v[this.labelName] && pattern(v[this.labelName]))
                    : this.options.filter(v => pattern(v))
            }

            options = _.sortString(options, this.searchString, label)

            if (_.isPlainObject(this.options)) {
                this.filteredOptions = {}
                for (let option of options) {
                    this.filteredOptions[option.key] = option[label]
                }
                return
            }

            this.filteredOptions = options
        },
        setSearchString() {
            this.searchString = ""
            const source = this.filteredOptions || this.options
            _.isNil(this.val) || (this.searchString = this.isArrayOfObjects
                    ? source.find(v => v[this.label] === this.val)?.[this.labelName]
                    : source[this.val]
            )
        },
        close(e) {
            if (e?.target.closest('.modal-content')) return
            this.$popup(this.dropdownName)?.isOpen && this.$dropdown(this.dropdownName).close()
        },
        reset() {
            this.val = this.searchString = ''
            this.input()
        },
        getValue() {
            return this.val
        },
        getSearchString() {
            return this.searchString
        }
    }
}
</script>

<style scoped lang="scss">
.form-find-select {
    &-item {
        padding: 10px;
        cursor: pointer;
    }
}
</style>
