var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[(_vm.order)?_c('FormOrder',{attrs:{"id":_vm.order},on:{"toggle":function($event){return _vm.redir()}}}):_vm._e(),(_vm.getMode())?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.order),expression:"!order"}]},[_c('h1',{staticClass:"section-page-title"},[_vm._v(" Поиск ")]),_c('FormOrderSearch',{on:{"search":function($event){return _vm.reload()}},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('DataTable',{key:_vm.load,attrs:{"cols":{
                number: 'Номер',
                lastStatusName: 'Статус заявки',
                driverSNP: 'Водитель',
                truckSemitrailerNumber: 'Номер ТС',
                loading: 'Загрузка',
                unloading: 'Разгрузка',
                // cargoDescription: 'Груз',
                // transportTypeId: 'Тип транспорта',
                // cargoWeight: 'Тоннаж',
                // transportNumber: 'Кол-во машин',
                // bidAmount: 'Ваша ставка',
            },"cell-bind":{
                lastStatusName: {class: 'clr-green'},
            },"cells":{
                number: function (v, item) { return [
                    {a: ("№" + v)},
                    {'div.clr-gold.tx-inline': _vm.unConfirmed(item) ? "(Не подтвержден)" : null},
                    {'div.clr-red.tx-inline': _vm.isCancelled(item) ? "(Отменен)" : null} ]; },
                // bidAmount: v => [{'div.clr-green.tx-inline': $$.price(v, '-')}],
                // transportTypeId: v => $$.find(transportTypes, 'id', v, 'name'),
                truckSemitrailerNumber: function (v) {
                    var split = v.toString().split(',')
                    return [{'div.tx-inline.clr-ltg-3': split[0]}, {'div.tx-inline.clr-ltg-3': split[1] || null}]
                },
                loading: function (v) { return [
                    {'div': ((v.region) + ", " + (v.city.name))},
                    {'small.clr-ltg-2.tx-lh-3': v.point},
                    {div: {'small.clr-ltg-3': _vm.$util.date.arrivingDate(v)}} ]; },
                unloading: function (v) { return [
                    {'div': ((v.region) + ", " + (v.city.name))},
                    {'small.clr-ltg-2.tx-lh-3': v.point},
                    {div: {'small.clr-ltg-3': _vm.$util.date.arrivingDate(v)}} ]; }
            },"adaptive":{cols: 7},"fetch":_vm.fetchData,"paginate":true,"assign":_vm.getAssign,"click":function (order) { return _vm.setOrder(order.id); }},on:{"setup":function (table) { return this$1.dataTable = table; }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }