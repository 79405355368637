import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import helpers from './helpers'
import './vendors'
import './components'
import './directives'
import App from '@pages/App.vue'

Object.assign(Vue.prototype, helpers)
Object.assign(Vuex.Store.prototype, helpers)

Vuex.Store.prototype.$router = router

Vue.config.productionTip = false
Vue.config.devtools = true

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
