import Vue from 'vue'
import {RequestLoader} from '@argab/request-service'

let loader

const load = () => {
    loader ||= Vue.prototype.$loading.show()
}
const hide = () => {
    loader?.hide()
    loader = null
}

export default class extends RequestLoader {
    _data
	
    constructor(data) {
        super()
        this._data = data
    }

    start() {
        console.log('Requests in pending status: ', this.pending)
        console.log('Request pending: ', `${this._data} `)
        console.log('Request pending: ', `${this} `)
        load()
    }

    end() {
        this.pending === 0 && hide()
    }

}
