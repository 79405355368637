<template>
    <div class="order-confirmation">
        <div class="">
            <h3>
                <FaIcon icon="exclamation-triangle" class="clr-red" /> Не подтвержден
            </h3>
            <template v-if="confirmationProhibited">
                <p class="clr-ltg-4">{{ confirmationComment }}</p>
            </template>
            <template v-else>
                <p>Чтобы начать перевозку необходимо пройти процесс подтверждения</p>
                <small>
                    Подтверждая, вы соглашаетесь с
                    <a class="link" @click="$service.file.getAndDownload({
                        repo: 'order',
                        repoMethod: 'carriageTerms',
                        params: id,
                        name: 'Условия перевозки',
                        errMsg: 'Не удалось загрузить условия перевозки'
                    })">условиями перевозки</a>
                </small>
            </template>
        </div>
        <div v-if="confirmationProhibited === false" class="flex flex-row-20 flex-center py-20">
            <button @click="declineOrder" class="btn btn-small btn-red">Отказаться</button>
            <button @click="getOrderConfirmation" class="btn btn-small btn-blue">Подтвердить</button>
        </div>
        <Confirm name="confirm-code">
            <p class="clr-ltg-2">Ваш код подтверждения заказа - {{code}}</p>
            <FormItem>
                <FormInput maxlength = "6" placeholder="Введите код подтверждения" v-model="sendCode" />
            </FormItem>
        </Confirm>
    </div>
</template>

<script>

    export default {
        name: "OrderConfirm",
        props: {
            id: {
                type: Number,
                required: true,
            },
            carrierOrderKey: {
                required: true,
                default: 0,
            },
            confirmationProhibited: {
                type: Boolean,
                default: false,
            },
            confirmationComment: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                code: null,
                sendCode: null
            }
        },
        methods: {
            getOrderConfirmation() {
                this.code = null
                const method = this.$service.account.access.isAdmin()
                    ? 'getCarrierOrderConfirmationCode'
                    : 'getDriverRftConfirmationCode'

                this.$repo('order')[method](this.id, this.carrierOrderKey).then(r => {
                    this.code = r.data.code
                    this.$confirm('confirm-code', {
                        title: 'Подтверждение',
                        buttonCancel: true,
                        buttons: [{
                            text: 'Подтвердить',
                            on: {click: this.confirmOrder}
                        }]
                    })
                }).alert('Не удалось получить код подтверждения заказа.')
            },
            confirmOrder() {
                if (this.code !== this.sendCode)
                    return this.$notify().warn('Не верный код.')
                const method = this.$service.account.access.isAdmin()
                    ? 'carrierOrderConfirm'
                    : 'driverRftConfirm'

                this.$repo('order')[method](this.id, {
                    confirmationCode: this.sendCode,
                    carrierOrderKey: this.carrierOrderKey 
                }).then(() => {
                    this.$popupClose()
                    this.$emit('confirmed')
					setTimeout(() => this.back(), 10000);
                }).done('Ваш заказ успешно подтвержден').alert('Не удалось подтвердить заказ.')
            },
			back () {
				this.$emit('toggle')
				this.order=null;
				this.$router.replace({name: 'OrdersActive', path: '/orders/active', query: null});
			},
            declineOrder() { 
                const method = this.$service.account.access.isAdmin()
                    ? 'carrierOrderDecline'
                    : 'driverRftDecline'

                this.$confirm({
                    title: 'Отказ от заказа',
                    text: 'Вы действительно хотите отказаться от заказа?',
                    onConfirm: () => {
                        this.$repo('order')[method](this.id, this.carrierOrderKey).then(() => {
                            this.$popupClose()
                            this.$emit('confirmed')
                        }).done('Заказ отклонен').alert('Не удалось отклонить заказ.')
                    }
                })
            }
        }
    }

</script>

<style scoped lang="scss">
</style>
