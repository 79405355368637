<template>
    <div>
        <nav v-click-outside="() => showNavMobile = false">
            <section class="section-pages section-container" :class="{'--align-left': pages.filter(p=>!!p).length===1}">
                <div ref="pages" class="section-pages-items" v-show="!isMobile">
                    <template v-for="(page, k) in pages">
                        <div v-if="page"
                             :key="k"
                             @click="page.active || toPage(page)"
                             class="section-pages-item"
                             :class="{active: page.active}"
                        > {{ page.title }}
                        </div>
                    </template>
                </div>
                <template v-if="isMobile">
                    <div v-if="activePage"
                         @click="showNavMobile = !showNavMobile"
                         class="section-pages-item --mobile active">
                        <span>{{ activePage.title }}</span>
                        <FaIcon icon="chevron-down"/>
                    </div>
                    <div v-else @click="showNavMobile = !showNavMobile" class="section-pages-item --mobile">
                        <FaIcon icon="bars"/>
                    </div>
                    <div v-show="showNavMobile" class="--pages-mobile">
                        <template v-for="(page, k) in pages">
                            <div v-if="page"
                                 :key="k"
                                 @click="toPage(page)"
                                 class="section-pages-item"
                                 :class="{active: page.active}">
                                {{ page.title }}
                            </div>
                        </template>
                    </div>
                </template>
            </section>
        </nav>
        <HScroll
            v-if="activePage && activePage.sub"
            :get-width="getSubPagesWidth"
            :container="() => $refs.subNav"
            :scroll-step="() => isMobile ? 120 : 300"
            :offset="getActiveSubPageOffsetLeft"
            :style="subPagesFitWindow() ? {width: '101.7%', maxWidth: '101.7%'} : false"
            class="section-container section-sub-pages-scroll"
        >
            <div ref="subNav" class="section-sub-pages">
                <template v-for="(subPage, k) in activePage.sub">
                    <div v-if="subPage"
                         :key="k"
                         @click.stop="setActive(activePage, activePage.sub[k]); toPage(subPage)"
                         class="section-sub-pages-item"
                         :class="{active: subPage.active, '--single': activePage.sub.length === 1}">
                        {{ subPage.title }}
                    </div>
                </template>
            </div>
        </HScroll>
    </div>
</template>

<script>

const SUB_NAV_ITEMS_MIN_WIDTH = 105

export default {
    name: "BaseLayoutNav",
    data() {
        return {
            pages: [],
            activePage: null,
            activeSubPage: null,
            isMobile: false,
            showNavMobile: false,
        }
    },
    created() {
        this.setPages()
        this.resetActive(this.pages)
        this.setActiveFromRoute()

        $(window).resize(() => this.mobileToggle())
        this.$nextTick(() => this.mobileToggle())
    },
    beforeRouteUpdate(to, from, next) {
        this.activeSubPage || this.setActiveFromRoute(to.path)
        next()
    },
    methods: {
        setPages() {
            const pages = []
            const filter = (pages, target) => {
                pages.forEach((page, index) => {
                    if (page.access === undefined || page.access === true) {
                        target[index] = page
                        if (page.sub instanceof Array && page.sub.length) {
                            const sub = page.sub.slice()
                            target[index].sub = []
                            filter(sub, target[index].sub)
                        }
                    }
                })
            }
            filter(this.$config.pages(this.$service.account.access), pages)
            this.pages = pages
        },
        mobileToggle() {
            const pages = $(this.$refs.pages)
            this.isMobile = false
            this.$nextTick(() => {
                let totalWidth = 0
                pages.find('.section-pages-item').each((i, el) => totalWidth += el.offsetWidth)
                this.isMobile = totalWidth >= $(window).width()
            })
        },
        toPage(page) {
            
			setTimeout(() => page.route && this.$link(page.route), 300)
        },
        resetActive(pages) {
            pages.forEach(page => {
                page.active = false
                page.sub && this.resetActive(page.sub)
            })
        },
        setActive(page, subPage) {
            this.$set(page, 'active', true)
            this.$set(this, 'activePage', page)
            if (subPage) {
                this.$set(subPage, 'active', true)
                this.$set(this, 'activeSubPage', subPage)
            }
            this.$nextTick(() => {
                this.showNavMobile = false
                this.subPagesFitWindow()
            })
        },
        setActiveFromRoute(path) {
            const trim = (path) => this.$filter.trim(path, '/')
            const route = trim(path || this.$route.path)
            const baseRoute = route.split('/')[0]
            const match = (route, target) => {
                let part = trim(route).split('-')
                return part[0] === trim(target) || trim(route) === trim(target)
            }
            for (const page of this.pages) {
                if (page && match(baseRoute, page.route)) {
                    if (page.sub?.length) {
                        const active = page.sub.find(p => match(route, p.route))
                        this.setActive(page, active)
                        break
                    }
                    this.setActive(page)
                    break
                }
            }
        },
        getSubPagesWidth() {
            let totalWidth = 0
            this.$refs.subNav && $(this.$refs.subNav)
                .find('.section-sub-pages-item')
                .each((i, item) => totalWidth += item.offsetWidth)
            return totalWidth
        },
        getActiveSubPageOffsetLeft() {
            const page = $(this.$refs.subNav).find('.active')
            return page.length ? page[0].getBoundingClientRect().left : false
        },
        subPagesFitWindow() {
            const totalWidth = this.getSubPagesWidth()
            const windowWidth = $(window).width()
            this.$refs.subNav && $(this.$refs.subNav)
                .find('.section-sub-pages-item')
                .css({minWidth: totalWidth < windowWidth ? SUB_NAV_ITEMS_MIN_WIDTH + 'px' : 'unset'})
            return totalWidth < windowWidth
        }
    },
}
</script>

<style scoped lang="scss">
nav {
	background-color: #3f8dcd;
	border-radius: 5px;
	padding-left: 20px;
	position: relative;
	
    .section-pages {
        display: flex;
        align-items: center;
        position: relative;
        height: 65px;

        &.--align-left {
            justify-content: left;

            .section-pages-items {
                margin-left: 4%;
            }
        }

        &-items {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .section-pages-item {
            font-family: $font-roboto;
            cursor: pointer;
            border-radius: 10px;
            padding: 20px 15px;
            margin-right: 5px;
            color: #fff;
            font-weight: 600;
            font-size: 12pt;
            letter-spacing: 1px;
            background-color: $blue;
            white-space: nowrap;

            &.active {
                background-color: $blue-dark-extra;
                cursor: auto;
            }

            &:not(.active):hover {
                background-color: $blue-50;
            }

            &.--mobile {
                position: relative;
                padding: 17px 40px;
                cursor: pointer;
                margin-right: 0;

                &.active {
                    padding-left: 25px;
                }

                > .fa-chevron-down {
                    color: #fff;
                    position: absolute;
                    right: 20px;
                    top: 41%;
                    font-size: 9pt;
                }
            }
        }

        .--pages-mobile {
            position: absolute;
            z-index: 2;
            background-color: $blue;
            top: $nav-height+px;
            min-width: 45%;
            border-radius: 0 0 5px 5px;
            -webkit-box-shadow: 2px 12px 49px 2px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 2px 12px 49px 2px rgba(34, 60, 80, 0.2);
            box-shadow: 2px 12px 49px 2px rgba(34, 60, 80, 0.2);

            .section-pages-item {
                border-radius: unset;
                margin-right: unset;
                text-align: center;
                border-bottom: $blue-51 1px solid;

                &:last-child {
                    border-radius: 0 0 5px 5px;
                    border-bottom: none;
                }

                &.active {
                    border-bottom: none;
                }
            }
        }
    }
}

.section-sub-pages-scroll {
    margin-top: 28px;
	position: absolute;
	justify-content: flex-end;
    /deep/ .--dir > div {
        padding: 16px 10px !important;
        font-weight: 600;
        font-size: 18pt;
    }
}

.section-sub-pages {
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
        font-family: $font-roboto;
        text-align: center;
        white-space: nowrap;
        cursor: pointer;
        padding: 15px 30px;
        color: $blue-dark;
        font-weight: 600;
        font-size: 10pt;
        letter-spacing: 1px;
        background-color: $blue-light-extra;
        border-right: #fff 1px solid;
        -webkit-box-shadow: 40px 15px 100px 5px rgba(218, 218, 218, 0.3);
        -moz-box-shadow: 40px 15px 100px 5px rgba(218, 218, 218, 0.3);
        box-shadow: 40px 15px 100px 5px rgba(218, 218, 218, 0.3);

        &:not(.active):hover {
            background-color: $blue-light-extra-2;
        }

        &:first-child {
            padding: 15px 25px 15px 29px;
            border-radius: 10px 0 0 10px;
        }

        &:last-child {
            padding: 15px 29px 15px 25px;
            border-radius: 0 10px 10px 0;
            border-right: none;
        }

        &.--single {
            padding: 21px 25px;
            border-radius: 10px
        }

        &.active {
            background-color: $blue;
            color: #fff;
            cursor: unset;
        }
    }
}
</style>
