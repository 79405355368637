<template>
    <div v-if="dataLoaded" :key="updateKey" class="card-preorder">
        <div @mouseleave="amountRangeChange(); amountRangeKey+=1" class="card-preorder-container">

            <h1 class="section-page-title mt-40 mb-0">Заявка №{{ meta.number }}</h1>

            <div v-if="!byFixedBid" class="clr-ltg-3 my-10">
                <h4 class="m-0">
                    <template v-if="preOrderExpired">
                        Тендер завершен
                    </template>
                    <template v-else>
                        Дата окончания: {{ $util.date.dateMsk(meta.endDateTime, true) }}
                    </template>
                </h4>
            </div>
            <div v-if="currentBid" class="flex flex-col-20 flex-center">
                <div class="clr-ltg flex flex-row-10">
                    <div>Основная ставка</div>
                    <FaIcon v-if="!byFixedBid && !preOrderExpired"
                            icon="trash"
                            title="Отменить текущее предложение"
                            @click="removeBid"
                            class="link link-gray-lt"
							style="font-size: 110%"
                    />
                </div>
                <h1 class="m-0 p-0">
                    {{ $$.price(countTotalSum([currentBid.amount, currentBid.amountOfFuel])) }}
                </h1>
                <table>
                    <tr>
                        <td class="tx-left pb-10">Деньгами: {{ $$.price(currentBid.amount) }}</td>
                        <td class="tx-left pl-15 pb-10">НДС: {{ currentBid.withTax ? 'Да' : 'Нет' }}</td>
                    </tr>
                    <tr>
                        <td class="tx-left">ГСМ: {{ $$.price(currentBid.amountOfFuel) }}</td>
                        <td class="tx-left pl-15">Кол-во машин: {{ currentBid.transportNumber }}</td>
                    </tr>
                </table>
                <div v-if="byFixedBid">
                    <h4 v-if="currentBid.position === 1" class="tx-c my-20">
                        <FaIcon icon="trophy" class="clr-gold"/>
                        Вы забрали заявку по фиксированной ставке
                    </h4>
                </div>
                <div v-else>
                    <h4 class="flex flex-center flex-row-10">
                        <span class="clr-ltg">Позиция ставки: </span>
                        <template v-if="currentBid.position === 1">
                            <FaIcon icon="trophy" class="clr-gold"/>
                            Лучшая
                        </template>
                        <template v-else>
                            {{ currentBid.position }} из {{ currentBid.totalBids }}
                            <FaIcon icon="arrow-down" class="clr-red"/>
                        </template>
                        <small>
                            <a v-if="canControlBids && !preOrderExpired" href="#" @click.prevent="openPreOrderBids">
                                Все ставки
                            </a>
                        </small>
                    </h4>
                </div>
                <div v-if="canControlBids && currentBid && currentBid.position > 1 && reserveBid === null"
                     class="tx-c">
                    <a href="#" @click.prevent="improveBid">Улучшить</a>
                </div>
            </div>
            <div v-if="reserveBid" class="flex flex-col-20 flex-center">
                <div class="flex flex-row-10">
                    <div class="clr-ltg">Ставка после тендера</div>
                    <FaIcon v-if="!byFixedBid"
                            icon="trash"
                            title="Отменить резервную ставку"
                            @click="removeBid(true)"
                            class="link link-gray-lt"
                    />
                </div>
                <h1 class="m-0 p-0">
                    {{ $$.price(countTotalSum([reserveBid.amount, reserveBid.amountOfFuel])) }}
                </h1>
                <table>
                    <tr>
                        <td class="tx-left pb-10">Деньгами: {{ $$.price(reserveBid.amount) }}</td>
                        <td class="tx-left pl-15 pb-10">НДС: {{ reserveBid.withTax ? 'Да' : 'Нет' }}</td>
                    </tr>
                    <tr>
                        <td class="tx-left">ГСМ: {{ $$.price(reserveBid.amountOfFuel) }}</td>
                        <td class="tx-left pl-15">Кол-во машин: {{ reserveBid.transportNumber }}</td>
                    </tr>
                </table>
                <div v-if="canControlBids" class="tx-c my-10">
                    <a href="#" @click.prevent="improveBid">Улучшить</a>
                </div>
            </div>
            <div class="card-preorder-details">
                <div>
                    <CarriageRoute :route="meta"/>
                </div>
                <div>
                    <FormOrderDetails :data="{model: meta}"/>
                    <div class="mt-20">
                        <p class="clr-ltg-2 m-0 mb-10">Менеджер:</p>
                        <p class="m-0 mb-10">{{ $$.get(meta, 'managerFullName') }}</p>
                        <p class="clr-ltg-2 m-0 mb-10">Телефон менеджера:</p>
                        <p class="m-0">{{ $$.get(meta, 'managerPhone') | mask('phone') }}</p>
                    </div>
					<p v-if="$$.get(meta, 'listReqDocs').length>0" class="clr-ltg-2 m-0 mt-10 mb-10">Документы для оплаты перевозки:</p>
					<div v-for="(doc) in $$.get(meta, 'listReqDocs')" :key="doc" class="mt-10">
                        <p class="m-0 mb-10">{{doc.document}} - {{doc.quantity}} шт.</p>
                    </div>
					<p v-if="this.$service.account.withTax" class="clr-ltg-2 m-0 mt-10 mb-10">Кол-во банковских дней:</p>
					<div v-if="this.$service.account.withTax" class="mt-10">
                        <p class="m-0 mb-10">15</p>
                    </div>
					<p v-if="!this.$service.account.withTax" class="clr-ltg-2 m-0 mt-10 mb-10">Кол-во банковских дней:</p>
					<div v-if="!this.$service.account.withTax" class="mt-10">
                        <p class="m-0 mb-10">21</p>
                    </div>
					<p  class="clr-ltg-2 m-0 mt-10 mb-10">Кол-во участников тендера:</p>
					<div class="mt-10">
                        <p class="m-0 mb-10">{{ $$.get(meta, 'aucParts') }}</p>
                    </div> 
                </div>
				<div v-if="canControlBids && preorder.rate.withoutTax.amount !=0 && meta.fixBid===true" class="section-bid-right">
					<div v-if="!this.$service.account.withTax" style="color: #233F55; font-family: Hauss-Bold, sans-serif; font-size: 20px; font-style: normal; font-weight: bold; line-height: 28px; margin-bottom: 12px;">{{ preorder.rate.withoutTax.amount }} руб.</div>
					<div v-if="this.$service.account.withTax" style="co	lor: #233F55; font-family: Hauss-Bold, sans-serif; font-size: 20px; font-style: normal; font-weight: bold; line-height: 28px; margin-bottom: 12px;">{{ preorder.rate.withTax.amount }} руб.</div>
					<div style="color: #607889; font-family: RobotoRegular, sans-serif; font-size: 14px; font-style: normal; font-weight: normal; line-height: 20px; text-align: center;">Фиксированная ставка</div>
					<button class="btn btn-blue" style="margin-top: 10px;" @click="bidFormFixed">
					Забрать груз
					</button>
				</div>
            </div>

            <div class="mt-20">
                <buttons initial>
                    <template
                        v-if="$service.account.access.hasAnyRole() && canControlBids && !currentBid && !reserveBid && meta.fixBid===false">
                        <template v-if="byFixedBid">
                            <h3 class="tx-c clr-green">
                                {{ $$.price($$.get(meta, 'fixedBidAmount')) }} - фиксированная ставка
                            </h3>
                            <button class="btn btn-green" @click="bidForm">
                                Забрать по фиксированной ставке
                            </button>
                        </template>
                        <button v-else class="btn btn-blue" @click="bidForm">
                            {{ preOrderExpired ? 'Сделать предложение' : 'Сделать предложение' }}
                        </button>
                    </template>
                </buttons>
                <BackToggle @toggle="$emit('toggle')" initial class="mt-15"/>
            </div>

            <Popup v-if="canControlBids && !preOrderExpired" name="popup-preorder-bids">
                <h2 slot="header" class="tx-c">Все ставки в тендере №{{ meta.number }}</h2>
                <div class="flex flex-center">
                    <button class="btn btn-blue btn-small mb-20 mt-20" @click="getPreOrderBidsInfo">
                        <FaIcon icon="info-circle"/>
                        механизм определения места в тендере
                    </button>
                </div>
                <DataTable
                    v-if="loadPreOrderBids > 0"
                    :key="loadPreOrderBids"
                    class="ml-15 mr-15"
                    :cols="{
                        position: 'Место в тендере',
                        amount: 'Ставка',
                        pctOfFuel: '% ГСМ в ставке',
                        withTax: 'Форма оплаты',
                    }"
                    :cells="{
                        amount: v => $$.formatNumber(v),
                        withTax: v => v === true ? 'С НДС' : 'Без НДС'
                    }"
                    :fetch="h => h(preOrderBids)"
                />
            </Popup>

            <Popup name="popup-pre-order-bid-form-fixed" class="card-preorder-bid-form">
                <h2 class="section-page-title tx-c">Заявка №{{ meta.number }}</h2>
                <div @mouseup="amountRangeChange" @touchend="amountRangeChange">
                    <Form center>
                        <div>
                            <h3 class="tx-c clr-ltg-3">Предложение за 1 машину</h3>
                            <p v-if="hintInfo" class="tx-c clr-ltg-2">{{ hintInfo }}</p>
                        </div>
                        <FormItem label="Деньгами">
                            <input
                                v-model.lazy="formData.amount"
                                @keyup="checkTotalSum(); bidCheck()"
                                ref="amount"
                                placeholder="₽"
                                class="js-currency"
                                type="text"
                                :disabled="true"
                            />
                        </FormItem>
                        <FormItem>
                            <label slot="label" class="amount-fuel-label"
                                   v-click-outside="() => amountOfFuelTooltip = false">
                                ГСМ
                                <FaIcon
                                    icon="question-circle"
                                    class="c-pointer clr-ltg-2"
                                    @click="amountOfFuelTooltip = !amountOfFuelTooltip"
                                />
                                <div v-show="amountOfFuelTooltip" class="amount-fuel-tooltip">
                                    Часть вознаграждения, получаемая ГСМ.
                                    При форме оплаты Без НДС и выборе части вознаграждения ГСМ,
                                    перевозка оформляется только Договором аренды на рейс.
                                </div>
                            </label>
                            <input
                                v-model.lazy="formData.amountOfFuel"
                                @keyup="checkTotalSum(); bidCheck()"
                                ref="amountOfFuel"
                                placeholder="₽"
                                class="js-currency"
                                type="text"
                                :disabled="true"
                            />
                        </FormItem>
                        <div v-if="rate" class="card-preorder-rate">
                            <div
                                v-for="(name, key) in filterRate"
                                :key="key"
                                :class="activeRate === key && '--active'" 
                                @click="setRate(key)"
                            >
                                <div>{{ name }}</div>
                                <div v-if="rate[key].amount && rate[key].amountOfFuel">
                                    <span class="tx-inline">{{ $$.price(rate[key].amount) }}</span>
                                    /
                                    <span class="tx-inline">{{ $$.price(rate[key].amountOfFuel) }} ГСМ</span>
                                </div>
                                <div v-else-if="rate[key].amount" class="tx-inline">
                                    {{ $$.price(rate[key].amount) }}
                                </div>
                                <div v-else class="tx-inline">
                                    {{ $$.price(rate[key].amountOfFuel) }} ГСМ
                                </div>
                            </div>
                        </div>
                        <div v-if="bidLockIsChecked()" class="py-20">
                            <small v-if="bidChecking === true" class="link">
                                Проверка ставки...
                            </small>
                            <small v-else-if="bidChecking === null" class="clr-red">
                                <FaIcon icon="exclamation-triangle"/> &nbsp;Не удалось проверить Вашу ставку.
                            </small>
                            <small v-else-if="bidCheckNotification" class="clr-red">
                                <FaIcon icon="exclamation-triangle"/> &nbsp;{{ bidCheckNotification }}
                            </small>
                        </div>
                        <template v-if="!formData.withTax">
                            <FormItem
                                v-if="amountBidRange && !bidLock"
                                label="Доля ставки деньгами:"
                                noWrap
                            >
                                <div
                                    :key="amountRangeKey"
                                    @touchend="amountRangeChange(true)"
                                    @mousedown="amountRangeSet"
                                    v-click-outside="amountRangeUnset"
                                >
                                    <RangeSlider
                                        :key="amountRangeMax"
                                        :min="0"
                                        :max="amountRangeMax"
                                        :step="amountRangeStep"
                                        v-model="rangeAmount"
                                    />
                                </div>
                            </FormItem>
                            <FormItem
                                v-if="bidLock !== null ? !bidLock : amountHint"
                                label="Доля ГСМ в ставке:"
                                noWrap
                            >
                                <div
                                    :key="amountRangeKey"
                                    @touchend="amountRangeChange(true)"
                                    @mousedown="amountRangeSet"
                                    v-click-outside="amountRangeUnset"
                                >
                                    <RangeSlider
                                        :key="amountFuelRangeMax"
                                        :min="0"
                                        :max="amountFuelRangeMax"
                                        :step="amountRangeStep"
                                        v-model="rangeAmountOfFuel"
                                    />
                                </div>
                                <small class="clr-ltg-2">{{ hintFuelInfo }}</small>
                            </FormItem>
                        </template>
                        <div class="flex flex-col-20">
                            <div v-if="getImproveBid && !byFixedBid" class="flex flex-align flex-row-20">
                                <div>Редактирование ставки</div>
                                <Toggle
                                    :key="bidLock"
                                    v-model="bidLock"
                                    @change="() => {
                                        setBidLock()
                                        resetBidCheck()
                                    }"
                                />
                            </div> 
                        </div>
                        <FormItem label="Транспорт контрагента" class="mt-20">
							<select class="form" v-model="transportFixBid">
								<option
									v-for="(item,index) in this.listUserTransport"
									v-bind:key="index"
									v-bind:value="item.id"
									v-text="item.descr"
								></option>
							</select>
                        </FormItem>
                        <div v-show="!lockTotalSum">
                            <b>Итого:</b>
                            <div class="">
                                {{ $$.price(this.totalSum) }}
                            </div>
                        </div>
                        <div class="flex flex-center my-20">
                            <button
                                class="btn"
                                :class="getImproveBid && isBestBid !== false ? 'btn-green' : 'btn-blue'"
                                @click="bidChecking || checkLink()">
                                {{
                                    getImproveBid
                                        ? (isBestBid === false ? 'Внести предложение' : (byFixedBid ? 'Продолжить' : 'Улучшить'))
                                        : 'Продолжить'
                                }}
                            </button>
                        </div>
                    </Form>
                </div>
            </Popup>
			
			
			<Popup name="popup-pre-order-bid-form" class="card-preorder-bid-form">
                <h2 class="section-page-title tx-c">Заявка №{{ meta.number }}</h2>
                <div @mouseup="amountRangeChange" @touchend="amountRangeChange">
                    <Form center>
                        <div>
                            <h3 class="tx-c clr-ltg-3">Предложение за 1 машину</h3>
                            <p v-if="hintInfo" class="tx-c clr-ltg-2">{{ hintInfo }}</p>
                        </div>
                        <FormItem label="Деньгами">
                            <input
                                v-model.lazy="formData.amount"
                                @keyup="checkTotalSum(); bidCheck()"
                                ref="amount"
                                placeholder="₽"
                                class="js-currency"
                                type="text"
                                :disabled="amountDisabled"
                            />
                        </FormItem>
                        <FormItem>
                            <label slot="label" class="amount-fuel-label"
                                   v-click-outside="() => amountOfFuelTooltip = false">
                                ГСМ
                                <FaIcon
                                    icon="question-circle"
                                    class="c-pointer clr-ltg-2"
                                    @click="amountOfFuelTooltip = !amountOfFuelTooltip"
                                />
                                <div v-show="amountOfFuelTooltip" class="amount-fuel-tooltip">
                                    Часть вознаграждения, получаемая ГСМ.
                                    При форме оплаты Без НДС и выборе части вознаграждения ГСМ,
                                    перевозка оформляется только Договором аренды на рейс.
                                </div>
                            </label>
                            <input
                                v-model.lazy="formData.amountOfFuel"
                                @keyup="checkTotalSum(); bidCheck()"
                                ref="amountOfFuel"
                                placeholder="₽"
                                class="js-currency"
                                type="text"
                                :disabled="amountFuelDisabled"
                            />
                        </FormItem>
                        <div v-if="rate" class="card-preorder-rate">
                            <div
                                v-for="(name, key) in filterRate"
                                :key="key"
                                :class="activeRate === key && '--active'"
                                @click="setRate(key)"
                            >
                                <div>{{ name }}</div>
                                <div v-if="rate[key].amount && rate[key].amountOfFuel">
                                    <span class="tx-inline">{{ $$.price(rate[key].amount) }}</span>
                                    /
                                    <span class="tx-inline">{{ $$.price(rate[key].amountOfFuel) }} ГСМ</span>
                                </div>
                                <div v-else-if="rate[key].amount" class="tx-inline">
                                    {{ $$.price(rate[key].amount) }}
                                </div>
                                <div v-else class="tx-inline">
                                    {{ $$.price(rate[key].amountOfFuel) }} ГСМ
                                </div>
                            </div>
                        </div>
                        <div v-if="bidLockIsChecked()" class="py-20">
                            <small v-if="bidChecking === true" class="link">
                                Проверка ставки...
                            </small>
                            <small v-else-if="bidChecking === null" class="clr-red">
                                <FaIcon icon="exclamation-triangle"/> &nbsp;Не удалось проверить Вашу ставку.
                            </small>
                            <small v-else-if="bidCheckNotification" class="clr-red">
                                <FaIcon icon="exclamation-triangle"/> &nbsp;{{ bidCheckNotification }}
                            </small>
                        </div>
                        <template v-if="!formData.withTax">
                            <FormItem
                                v-if="amountBidRange && !bidLock"
                                label="Доля ставки деньгами:"
                                noWrap
                            >
                                <div
                                    :key="amountRangeKey"
                                    @touchend="amountRangeChange(true)"
                                    @mousedown="amountRangeSet"
                                    v-click-outside="amountRangeUnset"
                                >
                                    <RangeSlider
                                        :key="amountRangeMax"
                                        :min="0"
                                        :max="amountRangeMax"
                                        :step="amountRangeStep"
                                        v-model="rangeAmount"
                                    />
                                </div>
                            </FormItem>
                            <FormItem
                                v-if="bidLock !== null ? !bidLock : amountHint"
                                label="Доля ГСМ в ставке:"
                                noWrap
                            >
                                <div
                                    :key="amountRangeKey"
                                    @touchend="amountRangeChange(true)"
                                    @mousedown="amountRangeSet"
                                    v-click-outside="amountRangeUnset"
                                >
                                    <RangeSlider
                                        :key="amountFuelRangeMax"
                                        :min="0"
                                        :max="amountFuelRangeMax"
                                        :step="amountRangeStep"
                                        v-model="rangeAmountOfFuel"
                                    />
                                </div>
                                <small class="clr-ltg-2">{{ hintFuelInfo }}</small>
                            </FormItem>
                        </template>
                        <div class="flex flex-col-20">
                            <div v-if="getImproveBid && !byFixedBid" class="flex flex-align flex-row-20">
                                <div>Редактирование ставки</div>
                                <Toggle
                                    :key="bidLock"
                                    v-model="bidLock"
                                    @change="() => {
                                        setBidLock()
                                        resetBidCheck()
                                    }"
                                />
                            </div>
                           
                        </div>
                        <FormItem label="Количество машин" class="mt-20">
                            <input type="number" min="1" max="1000" v-model="formData.transportNumber">
                        </FormItem>
                        <div v-show="!lockTotalSum">
                            <b>Итого:</b>
                            <div class="">
                                {{ $$.price(this.totalSum) }}
                            </div>
                        </div>
                        <div class="flex flex-center my-20">
                            <button
                                class="btn"
                                :class="getImproveBid && isBestBid !== false ? 'btn-green' : 'btn-blue'"
                                @click="bidChecking || bid()">
                                {{
                                    getImproveBid
                                        ? (isBestBid === false ? 'Внести предложение' : (byFixedBid ? 'Продолжить' : 'Улучшить'))
                                        : 'Продолжить'
                                }}
                            </button>
                        </div>
                    </Form>
                </div>
            </Popup>
			
			<Popup name="sms-send">
			<FormCodeConfirm
				requestCodeTitle="На Ваш номер телефона отправлен SMS с кодом подтверждения ставки"
				type="PHONE"
				:dest="$service.account.get('phone')"
				:get-code="true"
				:endpoint="this.$config.api().codeConfirm.confirmRequisites"
				:request-code-endpoint="this.$config.api().codeConfirm.requisitesRequestConfirmation"
				:resend-request-code-endpoint="this.$config.api().codeConfirm.requisitesResendCode"
				:use-api-key="false"
				:request-code-title="true"
				:confirm-cancel-btn="true"
				:compact="true"
				:token="1342903"
				:hide-send-code-button="false"
				@confirmed="() => {

					this.$popupClose()
				}"
				class="block-wide block-center"
			/>
			</Popup>
			<Confirm name="confirm-code">
				<p class="clr-ltg-2">Ваш код подтверждения ставки - {{code}}</p>
				<FormItem> 
					<FormInput maxlength = "6" placeholder="Введите код подтверждения" v-model="sendCode" />
				</FormItem>
			</Confirm>
			
            <Confirm name="reserve-bid-confirm">
                <p>Предложение сделано после завершения тендера и не будет учтено при определении победителя.</p>
                <p>Предложение будет зарезервировано на случай, если участники основного тендера не смогут осуществить
                    перевозку.</p>
                <p class="clr-ltg-2">
                    <input type="checkbox" @click="hideReservedBidNotification = !hideReservedBidNotification">
                    Больше не показывать это сообщение
                </p>
            </Confirm>
        </div>
    </div>
</template>

<script>

import CarriageRoute from '@pages/order/CarriageRoute.vue'
import FormOrderDetails from '@pages/order/form/FormOrderDetails.vue'
import RangeSlider from 'vue-range-slider'
import {getMessage, RequestWatch} from '@/api/service/request'
import FormCodeConfirm from '@pages/auth/form/FormCodeConfirm.vue'

const BID_DATA = {
    amount: "",
    amountOfFuel: "",
    amountOfGS: 0,
    transportNumber: 1,
	assignId: "",
    withTax: false,
    isReserveBid: false,
}

export default {
    name: "FormPreOrder",
    props: {
        preorder: {
            type: Object,
            required: true
        }
    },
    components: {
        CarriageRoute,
        FormOrderDetails,
        RangeSlider,
		FormCodeConfirm,
    },
    data () {
        return {
            meta: _.cloneDeep(this.preorder),
            dataLoaded: false,
            canControlBids: false,
            formData: null,
            hintInfo: '',
            hintFuelInfo: '',
            amountHint: false,
            amountRangeMax: 0,
            amountFuelRangeMax: 0,
            amountRangeStep: 100,
            amountBidRange: false,
            amountOfFuelTooltip: false,
            amountDisabled: false,
            amountFuelDisabled: false,
            getImproveBid: false,
            loadPreOrderBids: 0,
            preOrderBids: null,
            bidLock: null,
            updateKey: 0,
            amountRangeKey: 0,
            hideReservedBidNotification: false,
            showReservedBidNotification: this.$service.store.isExpired('reserved_bid_notification'),
            bidChecking: false,
            bidCheckNotification: '',
            isBestBid: null,
            calculateFuel: false,
            lockTotalSum: false,
            rangeAmount: 0,
            rangeAmountOfFuel: 0,
            rangeSet: false,
            activeRate: null,
			transportFixBid: null,
			listUserTransport: this.fetchTransport(),
			filterRate: null,
            filterRateWithoutTax: {
                withoutTax: 'без НДС',
                fuelHalf: '50% ГСМ',
            },
            filterRateWithTax: {
                withTax: 'с НДС',
            },
            versionOfDataBeforeBid: null,
            code: null,
            sendCode: null,
        }
    },
    computed: {
		totalSum () {
            const data = this.formatBid()
            _.set(this, 'formData.amount', data.amount ? _.formatNumber(data.amount) : "")
            _.set(this, 'formData.amountOfFuel', data.amountOfFuel ? _.formatNumber(data.amountOfFuel) : "")
            return this.countTotalSum([data.amount, data.amountOfFuel])
        },
        preOrderExpired () {
            const d = this.$util.date
            return d.toUtc(d.curDate()) >= d.toUtc(d.dateFromMsk(this.meta.endDateTime))
        },
        currentBid () {
            return _.get(this.meta, 'currentBid', null)
        },
        reserveBid () {
            return _.get(this.meta, 'currentReserveBid', null)
        },
        byFixedBid () {
            return _.get(this.meta, 'byFixedBid') === true
        },
        rate () {
            return this.formData?.rate
        }
    },
    watch: {
        hideReservedBidNotification (v) {
            v === true ? this.$service.store.setExpire('reserved_bid_notification') : this.$service.store.rm('reserved_bid_notification')
            this.showReservedBidNotification = !v
        },
        getImproveBid (v) {
            this.amountBidRange = v === true
        },
        amountRangeMax () {
            this.rangeAmount = _.num(this.formData.amount)
        },
        amountFuelRangeMax () {
            this.rangeAmountOfFuel = _.num(this.formData.amountOfFuel)
        },
        rangeAmount (v) {
            this.formData.amount = v
        },
        rangeAmountOfFuel (v) {
            this.formData.amountOfFuel = v
        },
        formData: {
            deep: true,
            handler (data) {

                if (!data || !this.rate?.[this.activeRate]) {
                    this.activeRate = null
                    return
                }
				if (this.activeRate === null){
				if (this.$service.account.withTax){
					this.activeRate = 'withTax'
					this.onToggleWithTax(true)
				}
				else {
					this.activeRate = 'withoutTax'
					this.onToggleWithTax(false)
				}
				}
                const rate = this.rate[this.activeRate]
                const amount = _.num(rate.amount)
                const amountOfFuel = _.num(rate.amountOfFuel)

                if (_.num(data.amount) !== amount || _.num(data.amountOfFuel) !== amountOfFuel) {
                    this.activeRate = null
                }
                
            }
        }
    },
	beforeMount() {
		this.fetchTransport();
	},
    created () {
	
		if (this.$service.account.withTax){
			this.filterRate = this.filterRateWithTax
		}
		else {
			this.filterRate = this.filterRateWithoutTax
		}
        if (this.$service.account.isLogged() === false) {
            this.canControlBids = false
            this.dataLoaded = true
            this.setBidForm()
            return
        }

        this.$service.account.hasRule('CAN_CONTROL_BIDS').finally(data => {
            this.canControlBids = data.result
            this.dataLoaded = true
            this.setBidForm()
            this.byFixedBid && this.$nextTick(() => this.onToggleWithTax())
        })
    },
    methods: {
			checkLink(){
				if (this.transportFixBid == null){
					this.$notify().alert('Укажите транспорт контрагента')
				}
				else {
					this.getOrderConfirmation()
				}
			},
            getOrderConfirmation() { 
                this.code = null
                const method = 'getConfCode'

                this.$repo('preorder')[method]().then(r => {
                    this.code = r.data.object
                    this.$confirm('confirm-code', {
                        title: 'Подтверждение',
                        buttonCancel: true,
                        buttons: [{
                            text: 'Подтвердить',
                            on: {click: this.confirmOrder}
                        }]
                    })
                }).alert('Не удалось получить код подтверждения заказа.')
            },

            confirmOrder() {
                if (this.code !== this.sendCode)
                    return this.$notify().warn('Не верный код.')
				else
                {
					this.bidFixed()
                    this.$popupClose()

                }
            },
			
		fetchTransport () {
			this.$repo('transport').assigns()
			.then(({list}) => {
			this.listUserTransport = list;
			})
			.catch((error) => {
			console.error(error);
			});
		},
        amountRangeChange (force) {
            (this.rangeSet || force === true) && this.form(() => {
                this.rangeAmountOfFuel = _.num(this.formData.amountOfFuel)
                this.rangeAmount = _.num(this.formData.amount)
                this.amountRangeKey += 1
            })
            this.rangeSet = false
        },
        amountRangeSet () {
            this.rangeSet = true
        },
        amountRangeUnset () {
            this.rangeSet = false
        },
        setRate (name) {
            const amount = _.num(this.rate[name].amount)
            const amountOfFuel = _.num(this.rate[name].amountOfFuel)
            const data = this.formData

            data.amount = this.rangeAmount = amount
            data.amountOfFuel = this.rangeAmountOfFuel = amountOfFuel
            data.withTax = name === 'withTax'

            this.activeRate = name
            this.$set(this, 'formData', data)
            this.onToggleWithTax()

            this.rangeAmount = amount
            this.rangeAmountOfFuel = amountOfFuel

            this.amountRangeMax <= this.rangeAmount && (this.amountRangeMax = data.withTax
                ? (this.rate['withTax'].amount || this.rate['withTax'].amountOfFuel)
                : (this.rate['withoutTax'].amount || this.rate['withoutTax'].amountOfFuel))
            this.amountFuelRangeMax <= this.rangeAmountOfFuel && (this.amountFuelRangeMax = data.withTax
                ? (this.rate['withTax'].amountOfFuel || this.rate['withTax'].amount)
                : (this.rate['withoutTax'].amountOfFuel || this.rate['withoutTax'].amount))
        },
        getPreOrder (cb) {
            this.$repo('preorder').get(this.meta.id, this.versionOfDataBeforeBid).then(({object}) => {
                this.$set(this, 'meta', object)
                _.isFunction(cb) && cb(object)

                const amount = this.currentBid ? this.countTotalSum([this.currentBid.amount, this.currentBid.amountOfFuel]) : null
                const reserveAmount = this.reserveBid ? this.countTotalSum([this.reserveBid.amount, this.reserveBid.amountOfFuel]) : null

                this.$emit('amount-changed', {id: object.id, amount, reserveAmount})
            })
               // .error(r => r.data.result === 'TENDER_COMPLETED' && this.$ok('Данный тендер завершен.'))
               // .alert('Не удалось загрузить заявку по тендеру.')
        },
        setBidForm () {
            this.$set(this, 'formData', {...BID_DATA})
            this.$set(this.formData, 'withTax', !!this.currentBid?.withTax)
            this.$set(this, 'amountHint', false)
            this.$set(this, 'hintInfo', '')
            this.$set(this, 'amountFuelRangeMax', 0)
            this.$set(this, 'amountRangeMax', 0)
            this.$set(this, 'getImproveBid', false)
            this.$set(this, 'bidLock', null)
            this.$set(this, 'updateKey', this.updateKey + 1)
        },        
		bidFormFixed () {
            const form = 'popup-pre-order-bid-form-fixed'
            const isOpen = () => this.$popup(form)?.isOpen
            const open = () => {
                isOpen() || (this.updateKey += 1)
                isOpen() || this.$nextTick(() => {
                    this.$popup(form, {
                        fullscreen: true,
                        footer: false,
                        pageScroll: false,
                        clickToClose: false,
                        onOpen: () => {
                            let withTax = Boolean(this.$service.account.withTax)
                            this.formData ||= {}
                            this.$set(this.formData, 'withTax', withTax)
                            this.onToggleWithTax(withTax)

                            this.getImproveBid || this.form(() => {

                                if (this.byFixedBid) {
                                    this.$set(this.formData, 'withTax', withTax = true)
                                    this.onToggleWithTax(withTax)
                                    this.hint()
                                } else if (this.getImproveBid) this.setBidLock()

                                this.setRate(withTax ? 'withTax' : 'withoutTax')
                            })
                        }
                    })
                })
            }
            open()
        },
        bidForm () {
            const form = 'popup-pre-order-bid-form'
            const isOpen = () => this.$popup(form)?.isOpen
            const open = () => {
                isOpen() || (this.updateKey += 1)
                isOpen() || this.$nextTick(() => {
                    this.$popup(form, {
                        fullscreen: true,
                        footer: false,
                        pageScroll: false,
                        clickToClose: false,
                        onOpen: () => {
                            let withTax = Boolean(this.$service.account.withTax)
                            this.formData ||= {}
                            this.$set(this.formData, 'withTax', withTax)
                            this.onToggleWithTax(withTax)

                            this.getImproveBid || this.form(() => {

                                if (this.byFixedBid) {
                                    this.$set(this.formData, 'withTax', withTax = true)
                                    this.onToggleWithTax(withTax)
                                    this.hint()
                                } else if (this.getImproveBid) this.setBidLock()

                                this.setRate(withTax ? 'withTax' : 'withoutTax')
                            })
                        }
                    })
                })
            }
			
            open()
        },
        formatBid () {
            const data = _.cloneDeep(this.formData)
            data.amount = _.num(data.amount)
            data.amountOfFuel = _.num(data.amountOfFuel)
            data.amountOfGS = _.num(data.amountOfGS)
            data.transportNumber = _.num(data.transportNumber) < 1 ? 1 : data.transportNumber
            data.isReserveBid = this.preOrderExpired
            data.byFixedBid = this.byFixedBid
            return data
        },
		
        formatBidFixed () {
		var BID_DATA_FIXED = {
			assignId: "",
			code: "",
			fio: "",
			phone: "",
			rate: {},
		}
			BID_DATA_FIXED.assignId = this.transportFixBid
			BID_DATA_FIXED.code = this.sendCode
			BID_DATA_FIXED.fio = this.$service.account.fullName()
			BID_DATA_FIXED.phone = this.$service.account.get('phone')
			BID_DATA_FIXED.rate[this.activeRate] = {}
			BID_DATA_FIXED.rate[this.activeRate] = this.rate[this.activeRate]
            return BID_DATA_FIXED
        },
        countTotalSum (arrayNum) {
            const num = []
            arrayNum.forEach(n => num.push(_.num(n)))
            const sum = _.sum(num)
            return sum.toString().match(/\./g) ? sum.toFixed(2) : sum
        },
        checkTotalSum () {
            setTimeout(() => {
                this.$set(this.formData, 'amount', this.$refs.amount.value)
                this.$set(this.formData, 'amountOfFuel', this.$refs.amountOfFuel.value)
            }, 50)
        },
        resetBidCheck (bidChecking) {
            this.bidChecking = bidChecking !== undefined ? bidChecking : false
            this.bidCheckNotification = ''
            this.isBestBid = null
        },
        bidCheck () {
            if (!this.bidLockIsChecked() || this.byFixedBid || !this.getImproveBid)
                return this.resetBidCheck()
            this.bidChecking || setTimeout(() => {
                const bid = this.formatBid()
                const data = new RequestWatch().find({uri: '/bid/check'})

                if (data !== null
                    && data.params.preorderId === this.meta.id
                    && data.params.amount === bid.amount
                    && data.params.amountOfFuel === bid.amountOfFuel
                    && data.params.withTax === bid.withTax
                    && data.params.isReserveBid === bid.isReserveBid
                ) {
                    return
                }
                this.bidChecking = true
                this.$rs.bg().repo('preorder').bidCheck(this.meta.id, {
                    preorderId: this.meta.id,
                    amount: bid.amount,
                    amountOfFuel: bid.amountOfFuel,
                    withTax: bid.withTax,
                    isReserveBid: bid.isReserveBid,
                }).then(({object}) => {
                    this.$set(this, 'isBestBid', object.itBestBid === true)
                    this.$set(this, 'bidChecking', false)
                    this.$set(this, 'bidCheckNotification', this.isBestBid ? '' : object.comment)
                }).alert(false).error(() => this.resetBidCheck(null)).catch(() => this.resetBidCheck(null))
            }, 500)
        },
        bid () {
            if (!_.num(this.formData.amount) && !_.num(this.formData.amountOfFuel))
                return this.$notify().warn('Ставка должна быть положительной.')
            this.$repo('preorder').postBid(this.meta.id, this.formatBid()).then(resp => {
                this.versionOfDataBeforeBid = resp.object.versionOfDataBeforeBid
                this.$popupClose()
                this.setBidForm()
                this.getPreOrder(obj => {
                    if (this.byFixedBid && getMessage(resp))
                        return this.$ok(getMessage(resp))
                    this.$notify().info('Ставка произведена.')
                    obj.currentBid && parseInt(obj.currentBid.position) > 1 && this.$confirm({
                        title: 'Улучшение ставки',
                        text: 'Ваша ставка не является лучшей, желаете ее улучшить?',
                        onConfirm: this.improveBid
                    })
                })
				this.$emit('toggle')
				this.$router.go(0)
            }).error(resp => {
                const messages = this.$config.api().bids.responseMessages
                if (resp.data.result in messages) {
                    const text = messages[resp.data.result]
                    const complete = resp.data.result === 'TENDER_ALREADY_COMPLETED'
                    complete && text.push(getMessage(resp))
                    return this.$ok({
                        text,
                        onConfirm: () => {
                            if (complete || resp.data.result !== 'BID_LIMIT_EXCEEDED') return
                            this.bidLock = null
                            this.hint()
                        }
                    })
                }
                this.$notify().alert(resp.data.params.message)
            }).alert(false)
        },
        bidFixed () {
				this.$repo('preorder').postFixedBid(this.meta.id, this.formatBidFixed()).then(resp => {
                this.versionOfDataBeforeBid = resp.object.versionOfDataBeforeBid
                this.$popupClose()
                this.setBidForm()
                this.getPreOrder(obj => {
                    if (this.byFixedBid && getMessage(resp))
                        return this.$ok(getMessage(resp))
                    this.$notify().info('Ставка произведена.')
					obj.currentBid
                })
				this.$emit('toggle') 
				this.$router.go(0)
            }).error(resp => {
                const messages = this.$config.api().bids.responseMessages
                if (resp.data.result in messages) {
                    const text = messages[resp.data.result]
                    const complete = resp.data.result === 'TENDER_ALREADY_COMPLETED'
                    complete && text.push(getMessage(resp))
                    return this.$ok({
                        text,
                        onConfirm: () => {
                            if (complete || resp.data.result !== 'BID_LIMIT_EXCEEDED') return
                            this.bidLock = null
                            this.hint()
                        }
                    })
                }
                 this.$notify().alert(resp.data.params.message)
            }).alert(false)
        },
        setWithTax (e) {

            const checked = e.target.checked

            this.activeRate = null
            this.formData.withTax = checked
            this.calculateFuel = this.getImproveBid && !checked
            this.onToggleWithTax(checked)

            if (this.bidLock) return false
            if (checked === true) this.formData.amountOfFuel = 0
            if (!this.amountHint) return false

            this.form(data => {
                if (this.byFixedBid && checked === false) {
                    const amountFuelRangeMax = this.amountFuelRangeMax
                    this.$set(this, 'amountFuelRangeMax', 0)
                    this.$nextTick(() => {
                        this.$set(this, 'amountFuelRangeMax', amountFuelRangeMax)
                        this.$set(this.formData, 'amountOfFuel', amountFuelRangeMax)
                        this.form(data => this.formData.amount = _.num(data.amount))
                    })
                    return
                }
                this.formData.amount = _.num(data.amount)
            })
        },
        onToggleWithTax (toggled, resetAmountOfFuel = true) {

            const disabled = Boolean(this.bidLock !== null ? !this.bidLock : this.amountHint)

            if (toggled !== undefined) {
                const tax = this.$refs.tax
                tax && (tax.toggled = toggled)
            }

            if (this.byFixedBid) {

                this.amountFuelDisabled = this.amountDisabled = true

            } else if (this.formData?.withTax) {

                this.bidLock !== null && resetAmountOfFuel && (this.formData.amountOfFuel = null)
                this.amountFuelDisabled = true
                this.amountDisabled = disabled

            } else {

                this.amountFuelDisabled = this.amountDisabled = disabled
            }
        },
        toggleHintInfo (toggle) {
            this.hintInfo = toggle ? 'Наша система подобрала наиболее оптимальные параметры для лучшего предложения' : ''
        },
        toggleHintFuelInfo (toggle) {
            this.hintFuelInfo = toggle ? 'Увеличение суммы Часть ГСМ - позволит получить Вам максимальную Итоговую ставку за перевозку.' : ''
        },
        hint (cb) {
            const withTax = !!_.get(this, 'formData.withTax')

            this.$repo('preorder').bidHint(this.meta.id, {
                withTax,
                isReserveBid: this.preOrderExpired,
                byFixedBid: this.byFixedBid,
            }).then(({object}) => {

                const amount = _.num(object.amount)
                const maxAmount = _.num(object.maxAmount)

                this.getImproveBid = this.amountHint = true

                this.toggleHintInfo(!this.byFixedBid)
                this.toggleHintFuelInfo(!this.byFixedBid)

                this.formData = _.merge(this.formData, object)
                this.formData = this.formatBid()
                this.formData.withTax = Boolean(this.$service.account.withTax)
                this.amountRangeMax = amount > maxAmount ? maxAmount : amount
                this.amountFuelRangeMax = _.num(this.formData.amountOfFuel)

                this.setRate(this.formData.withTax ? 'withTax' : 'withoutTax')
                this.onToggleWithTax(this.formData.withTax)

                this.getImproveBid && !this.byFixedBid && this.$set(this, 'bidLock', false)
                _.isFunction(cb) && cb(object)

                document.body.style.overflow = 'hidden'
            }).alert('Не удалось получить предложение по улучшению ставки.')
        },
        improveBid () {
            this.setBidForm()
            this.getImproveBid = true
            this.hint(() => this.bidForm())
        },
        form (cb) {
            this.$repo('preorder').bidForm(this.meta.id, {
                ...this.formatBid(),
                calculateFuel: this.calculateFuel
            }).then(({object}) => {

                this.formData.amount = _.num(object.amount)
                this.formData.amountOfFuel = _.num(object.amountBidOfFuel)
                this.formData.rate = object.rate

                this.amountFuelRangeMax = _.num(object.maxAmountOfFuel)
                this.amountRangeMax = _.num(object.maxAmount)
                this.$set(this, 'formData', this.formatBid())

                setTimeout(() => this.lockTotalSum = false, 100)
                _.isFunction(cb) && cb(object)

            }).alert('Не удалось проверить тариф по тендеру.')
        },
        bidLockIsChecked () {
            return this.bidLock === true
        },
        setBidLock () {
            setTimeout(() => {
                this.calculateFuel = this.getImproveBid && !this.bidLock
                this.onToggleWithTax()
                if (this.bidLock === false) {
                    this.bidLock = null
                    this.hint()
                }
                this.toggleHintInfo(!this.bidLock && !this.byFixedBid)
                this.toggleHintFuelInfo(!this.bidLock)
            }, 10)
        },
        removeBid (isReserveBid) {
            this.$confirm({
                title: 'Удалить предложение',
                text: 'Вы действительно хотите отменить текущее предложение?',
                onConfirm: () => {
                    this.$repo('preorder').deleteBid(this.meta.id, {
                        isReserveBid: isReserveBid === true
                    }).then(() => {
                        this.currentBid && this.$set(this.meta.currentBid, 'withTax', false)
                        this.getPreOrder()
                        this.setBidForm()
                    }).done('Ставка успешно отменена.').alert('Не удалось отменить ставку.')
                }
            })
        },
        openPreOrderBids () {
            this.$repo('preorder').bids(this.meta.id).then(({list}) => {
                this.preOrderBids = _.orderBy(list, 'position', 'asc')
                this.loadPreOrderBids += 1
                this.$popup('popup-preorder-bids', {
                    fullscreen: true,
                    clickToClose: false
                })
            }).alert('Не удалось загрузить все ставки в тендере.')
        },
        getPreOrderBidsInfo () {
            this.$service.file.getAndDownload({
                repo: 'preorder',
                repoMethod: 'agreement',
                name: 'Информация о ставке',
                errMsg: 'Не удалось загрузить информацию о ставке'
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.card-preorder {
	.section-bid-right {
			display: flex;
			max-height: 150px;
			margin-left: 200px;
			background-color: #f9f9f9;
			border-radius: 10px;
			align-items: center;
			padding: 24px;
			height: inherit;
			position: relative;
			flex-direction: column;
			@media (max-width: 1024px) {
				width: auto;
				padding-right: 20px;
				&:after {
					left: 88% !important;
				}
			}
		}
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        row-gap: 20px;
        padding-top: 20px;

        .section-page-title {
            padding-left: 0;
        }
    }

    &-rate {
        display: flex;
        gap: 5px;
        margin-bottom: 20px;

        > div {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            justify-content: center;
            gap: 5px;
            color: #fff;
            background-color: $gray-85;
            padding: 5px;
            cursor: pointer;
            font-size: 0.9em;

            &.--active {
                background-color: $blue-50;
            }

            > div {
                text-align: center;

                &:first-child {
                    border-bottom: #ddd 1px solid;
                    padding-bottom: 5px;
                }

                &:last-child {
                    font-size: 0.7em;
                }
            }
        }
    }

    &-details {
        display: flex;
        flex: 1;
        flex-direction: row;
        text-align: left;
        gap: 50px;

        > div {
            max-width: 300px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;
            gap: 0;
            row-gap: 20px;
            > div {
                border-bottom: #dadada 1px solid;
                width: 100%;
                max-width: 100%;

                > div {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            /deep/ .form-order-details {
                min-width: auto;

                &-model {
                    .wrapper {
                        flex-direction: column;
                        row-gap: 5px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        /deep/ .carriage-route {
            > h4 {
                margin-top: 0;
                font-size: 1.5em;
                color: $black-50;
            }

            li {
                padding-bottom: 20px;
            }
        }
    }

    &-bid-form {
        text-align: left;
    }

    .amount-fuel {
        &-label {
            position: relative;
        }

        &-tooltip {
            position: absolute;
            z-index: 100;
            background-color: #fff;
            padding: 10px;
            box-shadow: $box-shadow-thin;
            line-height: 1.2rem;
            margin-top: 5px;
        }
    }
	
}
</style>
