<template>
    <div  v-on:keyup.enter="onEnter" v-if="list" class="multi-select"  @click="drop" tabindex="0">
        <slot></slot>
        <div v-if="$slots.placeholder && !selected.length" class="multi-select-placeholder">
            <slot name="placeholder"></slot>
        </div>
        <div ref="inner" class="multi-select-inner"></div>
        <Dropdown  v-on:keyup.enter="onEnter" :name="name" class="multi-select-dropdown" v-click-outside="close">
            <ul ref="items" class="list">
                <li v-for="(item, k) in list" :key="k" class="multi-select-dropdown-item">
                    <input type="checkbox" v-on:keyup.enter="onEnter" :checked="selected.includes(item[label])"/>
                    <div class="">
                        {{ item[labelName] }}
                    </div>
                </li>
            </ul>
        </Dropdown>
        <div v-show="false">
            <div ref="output" class="multi-select-output">
                <slot name="output"/>
                <div v-for="(id, key) in selected" :key="key" class="--item">
                    <div>
                        {{ $$.find(list, label, id, labelName) }}
                    </div>
                    <div title="Удалить" @click.stop="clear(id)" class="--remove">&times;</div>
                </div>
                <div class="--clear" title="Удалить все" @click.stop="clear()">
                    &times;
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Multiselect",
    props: {
        data: {
            type: [Function, Array],
            required: true
        },
        label: {
            type: String,
            required: false,
            default: 'id',
        },
        labelName: {
            type: String,
            required: false,
            default: 'name',
        },
        value: Array,
        output: String,
    },
    data() {
        return {
            name: 'multi-select-' + this.$uuid.v1(),
            list: null,
            selected: [],
            lastSelection: [],
        }
    },
    created() {
        this.value && this.value.length && (this.selected = this.value.map(item => _.isPlainObject(item) ? item[this.label] : item))
        _.isFunction(this.data) ? this.data(list => this.list = list) : this.list = [...this.data]
        this.set()
    },
    methods: {
        onEnter() {
			this.$popup(this.name).close()
        },
        drop() {

            if (this.$popup(this.name)?.isOpen) {
                this.$popup(this.name).close()
                return
            }

            this.$dropdown(this.name, {
                el: this.$el,
                onOpen: () => {
                    this.$emit('opened', this.selected)
                },
                onClose: () => {
                    this.close()
                    this.$emit('closed', this.selected)
                },
                fullscreen: false,
                showOverlay: false,
                style: {
                    maxWidth: '300px',
                    maxHeight: '400px',
                    minHeight: '300px',
                },
            })
        },
        getOutput() {
            return this.output ? $(this.output) : $(this.$refs.inner)
        },
        resetOutput() {
            this.getOutput().length && this.getOutput().find('.multi-select-output').remove()
        },
        set() {
            const selected = [...this.selected]
            this.$emit('input', selected)
            this.$emit('selected', selected)
            this.$nextTick(() => {
                const output = this.getOutput()
                if (output.length) {
                    this.resetOutput()
                    selected.length && this.$nextTick(() => {
                        $(this.$refs.output).appendTo(output[0])
                    })
                }
            })
        },
        select() {
            $(this.$refs.items).find('input').each((i, el) => {
                const value = this.list[i][this.label]
                $(el).is(':checked')
                    ? this.selected.push(value)
                    : this.$delete(this.selected, this.selected.findIndex(v => v === value))
            })
            this.selected = _.uniq(this.selected)
        },
        clear(id) {
            if (id !== undefined) {
                this.$delete(this.selected, this.selected.indexOf(id))
                this.lastSelection = [...this.selected]
            } else {
                this.selected = []
                this.lastSelection = []
            }
            this.set()
            this.$emit('clear', this.selected)
        },
        close(e) {
            if (e?.target.closest('.modal-content')) return
            this.select()
            if (!this.selected.length) {
                this.resetOutput()
                this.lastSelection.length && this.set()
            } else {
                let selected = this.lastSelection.length > this.selected.length
                selected || this.selected.some(el => this.lastSelection.includes(el) ? false : (selected = true))
                selected && this.set()
            }
            this.lastSelection = this.selected.length ? [...this.selected] : []
        }
    }
}

</script>

<style lang="scss">

.multi-select {

    position: relative;

    &-placeholder {
        position: absolute;
        color: $gray-85;
        top: 25%;
        left: 3%;
    }

    &-dropdown-item {
        display: flex;
        flex-direction: row;
        padding: 10px;
        > input {
            margin-right: 15px;
        }
    }

    &-output {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-right: 20px;
        padding-top: 5px;

        .--item {
            margin-bottom: 5px;
            display: flex;
            align-items: flex-start;
            background-color: #1a83d6;
            color: #fff;
            font-size: 0.9em;
            white-space: nowrap;
            padding: 2px 5px;
            border-radius: 4px;
            margin-right: 5px;

            .--remove {
                width: 7px;
                height: 7px;
                margin-left: 10px;
                margin-right: 5px;
                font-size: 1.5em;
                cursor: pointer;
            }
        }

        .--clear {
            cursor: pointer;
            position: absolute;
            right: 1px;
            top: 45%;
            background-color: #fff;
            padding: 0 3px;
            text-align: center;
            color: dimgray;
            font-size: 2.0em;
            height: 5px;
            line-height: 5px;
        }
    }
}

</style>
