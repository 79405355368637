<template>
    <div :class="inline ? 'countdown-inline' : 'countdown'" :style="setColor()">
        <ul v-if="!expired">
            <li v-if="viewDate && showDays" class="countdown-days">
                <p class="countdown-digit">{{ twoDigits(days) }}</p>
                <p class="countdown-text">{{ shorten ? 'ДН' : 'Дней' }}</p>
            </li>
            <li v-if="viewTime" class="countdown-hours">
                <p class="countdown-digit">{{ twoDigits(hours) }}</p>
                <p class="countdown-text">:</p>
            </li>
            <li v-if="viewTime" class="countdown-minutes">
                <p class="countdown-digit">{{ twoDigits(minutes) }}</p>
                <p class="countdown-text">:</p>
            </li>
            <li v-if="showSeconds" class="countdown-seconds">
                <p class="countdown-digit">{{ twoDigits(seconds) }}</p>
                <p class="countdown-text"></p>
            </li>
        </ul>
        <div v-else class="countdown-expired">{{ expiredText }}</div>
    </div>
</template>

<script>
export default {
    name: "Countdown",
    props: {
        date: {
            type: String,
            required: true
        },
        expiredText: {
            type: String,
            default: "Истекло"
        },
        showSeconds: {
            type: Boolean,
            default: true
        },
        viewTime: {
            type: Boolean,
            default: true
        },
        inline: {
            type: Boolean,
            default: true
        },
        shorten: {
            type: Boolean,
            default: false
        },
        roundDays: {
            type: Boolean,
            default: false
        },
        showDays: {
            type: Boolean,
            default: true
        },
    },
    data () {
        return {
            interval: null,
            now: 0,
			isColor: null,
        }
    },
    created () {
        this.setNow()
    },
    mounted () {
        this.expired || (this.interval = setInterval(() => this.setNow(), 1000))
    },
    computed: {
        expired () {
            return this.now > this.getDate
        },
        getDate () {
            return Math.trunc(Date.parse(this.date) / 1000)
        },
        seconds () {
            return (this.getDate - this.now) % 60
        },
        minutes () {
            return Math.trunc((this.getDate - this.now) / 60) % 60
        },
        hours () {
            return Math.trunc((this.getDate - this.now) / 60 / 60)
        },
        days () {
            return Math.trunc((this.getDate - this.now) / 60 / 60 / 24)
        },
        viewDate () {
            return this.roundDays ? this.getDate >= 1 : true
        },
        viewSeconds () {
            return this.roundDays ? (this.showSeconds && this.getDate < 1) : this.showSeconds
        },
    },
    methods: {
		setColor() {
		console.log(this.minutes + " " + this.hours + " " + this.days)
			if(this.minutes<5 && this.hours===0 && this.days===0) {
				this.isColor = 'red';
			} else {
				this.isColor = 'black';
			}
			console.log("ddd" + this.isColor)
			return { color: this.isColor};
		},	
        setNow () {
            this.now = Math.trunc((new Date()).getTime() / 1000)
        },
        twoDigits (val) {
            return val.toString().length <= 1 ? "0" + val.toString() : val.toString()
        }
    },
    beforeDestroy () {
        clearInterval(this.interval)
    },
}
</script>

<style scoped>

.countdown ul, .countdown-inline ul {
    list-style-type: none;
    margin: 0 !important;
    padding: 0 !important;
	font-size: 15px;
}

.countdown ul > li .countdown-digit,
.countdown-inline ul > li .countdown-digit {
    padding-left: 5px;
    font-weight: bold
}

.countdown-inline ul {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.countdown-inline ul > li {
    display: inline-block !important;
    white-space: nowrap
}

.countdown-inline ul > li > p {
    display: inline-block !important
}

.countdown-inline ul > li > p.countdown-text {
    margin-left: 5px;
}

</style>
