<template>
    <div class="data-table" :class="{'data-table-boxed': box, '--no-shadow': noShadow}">
        <table :key="updateKey" v-bind="getBind()">
            <thead>
            <tr>
                <template v-for="(dataCol, col) in columns">
                    <th
                        :key="col"
                        v-if="dataCol.name !== false"
                        v-on="dataCol.on"
                        v-bind="dataCol.bind"
                    >
                        <div v-if="$$.isFunction(dataCol.name)" v-html="dataCol.name()"/>
                        <template v-else-if="$$.isArray(dataCol.name)">
                            <div v-for="(v, k) in dataCol.name" :key="k" v-html="v"/>
                        </template>
                        <Render v-else-if="$$.isPlainObject(dataCol.name)" :data="dataCol.name"/>
                        <template v-else>{{ dataCol.name }}</template>
                    </th>
                </template>
            </tr>
            </thead>
            <tbody>
            <template v-if="$$.isArray(list) && !list.length">
                <tr>
                    <td :colspan="Object.keys(cols).length">
                        <Render v-if="$$.isObject(empty)" :data="empty"/>
                        <p v-else>{{ empty }}</p>
                    </td>
                </tr>
            </template>
            <template v-else>
                <template v-for="(item, key) in (filtered || list)">
                    <tr v-if="check(item, key)" :key="`row-${key}`" v-bind="getRowBind(item, key)">
                        <template v-for="(col, k) in Object.keys(cols)">
                            <template v-if="cols[col] !== false">
                                <template v-for="cell in [getCell(col, item, key)]">
                                    <td
                                        v-if="columns[col] !== false"
                                        :key="`${cell.key}-${k}-${cellKeys[col][key]}`"
                                        @click="e => getAction(col, item, key, e)"
                                        v-bind="cell.bind"
                                    >
                                        <div class="data-table-col-name-mobile">
                                            <div
                                                v-if="$$.isFunction(columns[col].name)"
                                                v-html="columns[col].name()"
                                            />
                                            <template v-else-if="$$.isArray(columns[col].name)">
                                                <div v-for="(v, k) in columns[col].name" :key="k+v" v-html="v"/>
                                            </template>
                                            <Render
                                                v-else-if="$$.isPlainObject(columns[col].name)"
                                                :data="columns[col].name"
                                            />
                                            <template v-else>{{ columns[col].name }}</template>
                                        </div>
                                        <Render v-if="$$.isObject(cell.value)" :data="cell.value" />
                                        <template v-else>{{ cell.value }}</template>
                                    </td>
                                </template>
                            </template>
                        </template>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>
        <div v-if="paginate && list !== null">
            <Pagination
                v-if="$$.isPlainObject(paginate) && paginate.pageCount"
                :key="pagesKey"
                :page-count="paginate.pageCount"
                v-bind="paginate"
                @setup="p => this.pages = p"
                @page-load="p => {
                    page = p-1
                    $emit('page-load', page)
                }"
            />
            <div v-else-if="!loaded" class="buttons my-20">
                <button class="btn btn-blue" @click="getData">
                    Загрузить еще
                </button>
            </div>
        </div>
    </div>
</template>

<script>

const DEFAULT_UNIQ_BY = 'id'

import Pagination from './Pagination.vue'

export default {
    name: "data-table",
    components: {Pagination},
    props: {
        data: {
            type: Array,
            required: false,
            default: null
        },
        cols: {
            type: Object,
            required: true
        },
        bind: {
            type: Object,
            required: false
        },
        colData: {
            type: [Object, Function],
            required: false
        },
        showColId: {
            type: Boolean,
            required: false,
            default: null,
        },
        rowBind: {
            type: [Object, Function],
            required: false
        },
        rowCheck: {
            type: Function,
            required: false,
            default: null
        },
        cellBind: {
            type: [Object, Function],
            required: false
        },
        cells: {
            type: Object,
            required: false
        },
        fetch: {
            type: Function,
            required: true
        },
		fetchDataSilent:  {
            type: Function,
            required: true
        },
        fetchOnLoad: {
            type: Boolean,
            required: false,
            default: true
        },
        assign: {
            type: [Object, Function],
            required: false
        },
        uniqBy: {
            type: String,
            required: false,
            default: DEFAULT_UNIQ_BY
        },
        click: {
            type: [Object, Function],
            required: false
        },
        paginate: {
            type: [Boolean, Object],
            required: false,
            default: false
        },
        pageStart: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 25
        },
        adaptive: {
            type: [Boolean, Object],
            default: true
        },
        highlight: {
            type: Boolean,
            default: true
        },
        empty: {
            type: [Object, Array, String],
            default: null
        },
        box: {
            type: Boolean,
            default: true
        },
        noShadow: Boolean
    },
    data () {
        return {
            adapt: null,
            list: null,
            showId: this.showColId !== null ? this.showColId : this.$app().isLocalhost(),
            updateKey: null,
            pagesKey: null,
            filtered: null,
            pages: null,
            page: 0,
            firstPageSize: 0,
            loaded: false,
            onClick: {
                cols: null,
                action: null
            },
            columns: {},
            cellKeys: {}
        }
    },
    created () {
        (this.fetchOnLoad && this.data === null) ? this.getData() : this.list = this.data
        this.getClickAction()
        Object.entries(this.cols).forEach(([name, data]) => this.columns[name] = this.getColData(name, data))
    },
    mounted() {
        this.$emit('setup', this)
		setInterval(this.getDataSilence, 20000)
    },
    methods: {
        getHandle () {
            return data => {

                _.isArray(data) || (data = [])

                if (!data.length || !data[0]) {
                    this.page && (this.page -= 1)
                    this.$emit('onload', this)
                    return
                }

                const uniqBy = this.uniqBy || DEFAULT_UNIQ_BY

                const items = data.slice()

                items.forEach(item => {
                    _.isPlainObject(item) && _.isNil(item[uniqBy]) && (item[uniqBy] = parseInt(_.randKey().substr(0, 5)))
                })

                if (!_.isArray(this.list) || _.isEmpty(this.list)) {

                    this.list = []
                    this.firstPageSize = items.length
                    this.firstPageSize > 0 && (this.firstPageSize >= this.pageSize || (this.loaded = true))

                } else {

                    this.loaded = items.length === 0 || items.length < this.firstPageSize
                }

                this.list = _.isArrayOfObjects(items) ? _.uniqBy(this.list.concat(items), uniqBy) : _.uniq(this.list.concat(items))

                this.$emit('onload', this)
            }
        },
		getHandleSilence () {
            return data => {

                _.isArray(data) || (data = [])

                if (!data.length || !data[0]) {
                    this.page && (this.page -= 1)
                    this.$emit('onload', this)
                    return
                }

                const uniqBy = this.uniqBy || DEFAULT_UNIQ_BY

                const items = data.slice()

                items.forEach(item => {
                    _.isPlainObject(item) && _.isNil(item[uniqBy]) && (item[uniqBy] = parseInt(_.randKey().substr(0, 5)))
                })

                if (!_.isArray(this.list) || _.isEmpty(this.list)) {

                    this.list = []
                    this.firstPageSize = items.length
                    this.firstPageSize > 0 && (this.firstPageSize >= this.pageSize || (this.loaded = true))

                }

                this.list = _.isArrayOfObjects(items) ? _.uniqBy(this.list = items, uniqBy) : _.uniq(this.list = items)

                this.$emit('onload', this)
            }
        },
        getClickAction () {

            const dataCols = Object.keys(this.cols)

            if (this.showId === false && dataCols.includes('id')) {
                this.cols.id = false
                delete dataCols.id
            }

            if (_.isPlainObject(this.click) && !_.isEmpty(this.click)) {
                if (_.isPlainObject(this.click.cols)) {
                    let cols = []
                    let action = {}
                    for (const key of this.click.cols) {
                        if (dataCols.includes(key) && _.isFunction(this.click.cols[key])) {
                            cols.push(key)
                            action[key] = this.click.cols[key]
                        }
                    }
                    this.onClick = {cols, action}
                } else {
                    let cols = _.isArray(this.click.cols) ? this.click.cols : dataCols
                    const exclude = _.isArray(this.click.exclude) ? this.click.exclude : null
                    exclude && (cols = cols.filter(el => !exclude.includes(el)))
                    this.onClick = {
                        cols,
                        action: _.isFunction(this.click.action) ? this.click.action : null
                    }
                }
            } else if (_.isFunction(this.click)) {
                this.onClick = {
                    cols: dataCols,
                    action: this.click
                }
            }
        },
        getAssign () {
            if (!this.page && this.pageStart) {
                this.page = this.pageStart - 1
            }
            this.page += 1
            const assign = this.paginate ? {
                pageSize: this.pageSize,
                page: this.page - 1
            } : {}
            const get = _.isFunction(this.assign) ? this.assign() : this.assign
            return Object.assign(assign, _.isPlainObject(get) ? get : {})
        },
        getData () {
            this.fetch(this.getHandle(), this.getAssign(), this)
        },
        getDataSilence () { 
            this.updateKey = null
            this.pagesKey = null
            this.filtered = null
            this.pages = null
            this.page = 0
            this.firstPageSize = 0
			this.fetchDataSilent(this.getHandleSilence(), this.getAssign(), this)
			console.log('dsdd ' + JSON.stringify(this.getAssign()))
			this.loaded = true
			this.update()
			
        },
        loadData (cb) {
            cb(this.getHandle(), this.getAssign());
            this.update()
        },
        loadPage (page) {
            setTimeout(() => this.pages?.loadPage(page), 50)
        },
        reloadPages () {
            this.pagesKey = _.randKey()
        },
        setPageTotal (total) {
            setTimeout(() => this.pages?.setPageTotal(total), 50)
        },
        reloadData (cb) {
            this.clearData()
            this.loadData(cb)
        },
        clearData () {
            this.list = this.filtered = null
            this.loaded = false
        },
        getIndex (find) {
            return this.getList().findIndex(find)
        },
        getList () {
            return this.list || []
        },
        getItem (find, index) {
            index === undefined && (index = this.getIndex(find))
            return index >= 0 ? (this.list[index] || null) : null
        },
        setItem (find, data, index) {
            const item = this.getItem(find, index)
            if (item === null) return false
            Object.assign(item, _.merge(item, data))
            this.update()
        },
        addItem (data) {
            this.getList().push(data)
            this.update()
        },
        removeItem (find, index) {
            index = index !== undefined ? (this.filtered ? -1 : index) : this.getIndex(find)
            if (index >= 0 && this.list && this.list[index]) {
                this.$delete(this.list, index)
                this.update()
            }
        },
        getAction (col, item, index, e) {
            const onClick = this.onClick || {}
            return (typeof onClick.action === 'object' && onClick.action && col in onClick.action)
                ? onClick.action[col](item, index, e, col, this)
                : ((onClick.cols && onClick.action && onClick.cols.includes(col))
                    ? onClick.action(item, index, e, col, this) : false)
        },
        getBind () {
            return _.isPlainObject(this.bind) ? this.bind : {}
        },
        getColData (col, name) {
            const colData = _.isFunction(this.colData) ? this.colData(col, name) : this.colData
            const data = {
                name,
                on: {},
                bind: {}
            }
            return _.merge(data, _.isPlainObject(colData) ? (col in colData ? colData[col] : colData) : {})
        },
        getRowBind (item, index) {
            const bind = _.isFunction(this.rowBind) ? this.rowBind(item, index) : this.rowBind
            return _.isPlainObject(bind) ? bind : {}
        },
        getCell (col, item, index) {
            this.cellKeys[col] ||= {}
            this.cellKeys[col][index] ||= index + ''

            const value = this.getCellValue(col, item, index)
            const empty = _.isEmpty(value) && !_.isNumber(parseFloat(value?.toString().trim()))
            return {
                key: index,
                bind: this.getCellBind({
                    style: (this.onClick?.cols && this.onClick.cols.includes(col)) ? {cursor: 'pointer'} : false,
                    class: {
                        '--empty': Boolean(empty)
                    }
                }, col, item, index),
                value
            }
        },
        getCellBind (data, col, item, index) {
            const bind = _.isFunction(this.cellBind) ? this.cellBind(_.get(item, col), item, col, index) : this.cellBind
            return _.isPlainObject(bind) ? _.merge({}, data || {}, col in bind ? bind[col] : {}) : data
        },
        getCellValue (col, item, index) {
            return this.cells && col in this.cells
                ? (_.isFunction(this.cells[col]) ? this.cells[col](_.get(item, col), item, index, this) : this.cells[col])
                : _.get(item, col)
        },
        check (item, index) {
            const check = !_.isFunction(this.rowCheck) || this.rowCheck(item, index)
            return check === true || check === undefined || check === null
        },
        filter (data) {

            let filtered = _.cloneDeep(this.list)

            _.isFunction(data?.filter) && (filtered = filtered.filter(o => data.filter(o)))

            if (_.isArray(data?.cols) && !_.isEmpty(data?.search)) {
                filtered = filtered.filter(o => {
                    let match = false
                    data.cols.some(col => {
                        const find = _.get(o, col)
                        return _.isNil(find) ? false : match = find.toString().match(new RegExp(data.search, 'gi'))
                    })
                    return match
                })
            }

            this.$set(this, 'filtered', _.isEmpty(filtered) ? null : filtered)
            this.update()
        },
        orderBy (cols, dir) {
            _.isArray(this.list) && (this.list = _.orderBy(this.list, cols, dir))
            _.isArray(this.filtered) && (this.filtered = _.orderBy(this.filtered, cols, dir))
            this.update()
        },
        update () {
            this.updateKey = _.randKey()
        },
        updateCell (col, index) {
            this.cellKeys[col] ||= {}

            if (index !== undefined) {
                this.cellKeys[col][index] ||= {}
                this.$set(this.cellKeys[col], index, _.randKey())
            } else {
                Object.keys(this.cellKeys[col]).forEach(index => {
                    this.$set(this.cellKeys[col], index, _.randKey())
                })
            }
        }
    }
}

</script>

<style lang="scss">

.data-table {
    max-width: 100%;
    overflow-x: auto;

    &-boxed {
        border-radius: 5px;
        box-shadow: 0 0 20px 7px rgba(236, 239, 244, 1);
        border: #deeeff 2px solid;
    }

    &.--no-shadow {
        box-shadow: none;
    }

    > table {
        width: 100%;
		overflow: unset;
        > thead {
			position: sticky;
			top: 0;
            > tr {
                > th {
                    padding: 17px 10px;
                    font-size: 1.14em;
                    text-align: left;
                    font-family: $font-proxima-bold;
					background-color: white;
					box-shadow: inset 0 -1px 0 #efefef;
                }
            }
        }

        > tbody {
            > tr {
                border-top: #e9f3ff 1px solid;
                border-bottom: #e9f3ff 1px solid;

                > td {
                    padding: 15px 12px;
                    text-align: left;
                    vertical-align: middle;

                    .data-table-col-name-mobile {
                        display: none;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            > thead {
                > tr {
                }
            }

            > tbody {
                > tr {
                    display: block;

                    > td {
                        display: block;

                        .data-table-col-name-mobile {
                            display: block;
                            margin-bottom: 10px;
                            color: #787878;
                        }

                        &.--empty {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

</style>
