<template>
    <Page>
        <div v-if="!assign && !showRules && !dataDriver">
            <FormStaffSearch v-model="search" @search="reload" />
            <div class="buttons" v-if="!($service.account.access.isAdmin() && $service.account.access.isEnterprise())">
                <button class="btn btn-blue btn-small mb-20" @click="toggle(null)">
                    Добавить
                </button>
            </div>
        </div>
        <div v-if="dataDriver && dataDriver.id !== currentDriverId">
            <FormStaffPermissions
                v-if="showRules"
                :key="dataDriver.id"
                :data="dataDriver"
                role="driver"
                @toggle="toggle"
            />
            <FormStaffDriver
                v-else
                :key="dataDriver.id"
                :driver="dataDriver"
                :can-view-permissions="canChangePerm"
                @add-user="add"
                @unlink-user="unlink"
                @rules="rules"
                @remove="remove"
                @toggle="toggle"
            />
        </div>
        <FormStaffLinkConfirm v-else-if="assign === true" linkType="driver" @toggle="toggle" />
        <DataTable
            :key="load"
            v-show="!assign && !dataDriver && !showRules"
            :cols="{
                id: 'ID',
                linkIcon: '',
                name: 'ФИО',
                status: 'Статус',
                phone: 'Номер телефона',
            }"
            :cells="{
                linkIcon: (v, item) => !item.linkToCarrierRequired ? null : [
                    {'div.user-link-icon': '', bind: {style: {width: '30px', height: '30px'}}}
                ],
                name: (v, item) => $filter.fullName(item),
                phone: v => $filter.mask(v, $app().mask.phone)
            }"
            :fetch="fetchData"
            paginate
            :click="driver => driver.id === currentDriverId || view(driver.id)"
            @setup="i => this.list = i"
        />
    </Page>
</template>

<script>

import FormStaffDriver from './form/FormStaffDriver.vue'
import FormStaffPermissions from './form/FormStaffPermissions.vue'
import FormStaffLinkConfirm from './form/FormStaffLinkConfirm.vue'
import FormStaffSearch from './form/FormStaffSearch.vue'

export default {
    name: "drivers",
    props: {
        id: Number
    },
    components: {
        FormStaffDriver,
        FormStaffPermissions,
        FormStaffLinkConfirm,
        FormStaffSearch
    },
    data() {
        return {
            load: 0,
            assign: false,
            list: null,
            search: {data: ''},
            currentDriverId: null,
            canChangePerm: null,
            dataDriver: null,
            showRules: false
        }
    },
    created() {
        this.currentDriverId = this.$service.account.access.isDriver() ? this.$service.account.get('userId') : null
        _.isNumber(this.id) && this.id !== this.currentDriverId && this.getDriver(this.id)
    },
	watch: {
		$route (to){
			if (!to.query.id) {
				this.$set(this, 'dataDriver', null)
				this.$router.replace({name: 'Drivers', query: null});
			}
		}
	},
    methods: {
        reload() {
            this.load += 1
        },
        toggle(toggle) {
            this.$set(this, 'assign', toggle === null ? !this.assign : toggle)
            this.$set(this, 'showRules', false)
            this.$set(this, 'dataDriver', null)
            this.$redirect({name: 'Drivers'}, true)
        },
        fetchData(h) {
            this.$service.account.hasRule('CAN_CHANGE_DRIVER_PERMISSIONS').then(can => {
                this.canChangePerm = can
                this.$repo('staff').drivers({search: this.search.data}).then(({list}) => h(list))
            })
        },
        getDriver(id) {
            this.$repo('staff').driverProfile(id).then(({object}) => {
                this.$set(this, 'dataDriver', object)
            }).alert(`Не удалось загрузить профиль водителя "${this.fullName(id)}".`)
			this.$router.push({name: 'Drivers', query: {id: id}})
			if (!id){
				this.toggle(false)
			}
        },
        fullName(id){
            return this.$filter.fullName(this.list?.getItem((v) => v.id === id))
        },
        add() {
            const id = this.dataDriver.id

            this.$repo('link_carrier').add('DRIVER', id).then(() => {
                this.getDriver(id);
                this.reload()
            })
                .done(`Водитель ${this.fullName(id)} добавлен в список Вашего персонала`)
                .alert('Не удалось привязать водителя.')
        },
        unlink() {
            this.$confirm({
                text: 'Вы действительно хотите отменить привязку водителя?.',
                onConfirm: () => {
                    this.$repo('link_carrier').cancel('DRIVER', this.dataDriver.id).then(() => {
                        this.assign = this.showRules = false
                        this.dataDriver = null
                        this.reload()
                    }).done('Заявка водителя отклонена.').alert('Не удалось отклонить заявку водителя.')
                }
            })
        },
        view(id) {
            this.$set(this, 'assign', false)
            this.$set(this, 'showRules', false)
            this.$set(this, 'dataDriver', null)
            this.getDriver(id)
        },
        rules() {
            this.$set(this, 'showRules', true)
            this.$set(this, 'assign', false)
        },
        remove() {
            const id = this.dataDriver.id
            const name = this.$filter.fullName(this.dataDriver)

            this.$confirm({
                text: 'Вы действительно хотите отменить привязку водителя?.',
                onConfirm: () => {
                    this.$repo('staff').deleteDriver(id).then(() => {
                        this.reload()
                        this.$set(this, 'assign', false)
                        this.$set(this, 'showRules', null)
                        this.$set(this, 'dataDriver', null)
                    }).done(`Водитель "${name}" удален из списка.`).alert(`Не удалось удалить водителя "${name}"`)
                }
            })
        }
    }
}

</script>
